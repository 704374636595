<template>
  <div class="pa-4">
    <v-row class="justify-center d-flex">
      <v-col
        cols="12"
        md="8"
        class="d-flex flex-column"
      >
        <Feature
          v-slot="{feature}"
          :feature-slug="featureNames.DATA_PRIVACY"
        >
          <div v-if="feature.config.hasConditions">
            <div>
              <h2 class="pr-4 d-inline">
                AGB und Datenschutz
              </h2>
            </div>
            <div
              class="d-flex"
              :class="{'flex-column align-start': $vuetify.breakpoint.smAndDown, 'align-end': $vuetify.breakpoint.mdAndUp}"
            >
              <v-checkbox
                v-model="acceptAgb"
                hide-details
                label="Ich akzeptiere die"
              />
              <div class="pl-8 pl-md-1">
                <a
                  :href="feature.config.conditionsUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                > AGB der {{ legalCompanyName }}</a>
              </div>
            </div>
          </div>
        </Feature>

        <div
          class="d-flex"
          :class="{'flex-column align-start': $vuetify.breakpoint.smAndDown, 'align-end': $vuetify.breakpoint.mdAndUp}"
        >
          <v-checkbox
            v-model="acceptPrivacy"
            hide-details
            label="Ich akzeptiere die"
          />
          <div class="pl-8 pl-md-1">
            <a
              class="px-2"
              target="_blank"
              rel="noopener noreferrer"
              :href="dataPrivacyUrl"
            > Datenschutzerklärung der {{ legalCompanyName }}</a>
          </div>
        </div>

        <Feature
          v-slot="{feature}"
          :feature-slug="featureNames.MATERIAL_GUIDE"
        >
          <div
            class="d-flex"
            :class="{'flex-column align-start': $vuetify.breakpoint.smAndDown, 'align-end': $vuetify.breakpoint.mdAndUp}"
          >
            <v-checkbox
              v-model="acceptGuideline"
              hide-details
              label="Ich habe den"
            />
            <div class="pl-8 pl-md-1">
              <a
                :href="feature.config.guideUrl"
                target="_blank"
                rel="noopener noreferrer"
              > Leitfaden zur Implementierung der {{ readablePlatformName }} Inhalte</a>
              erhalten
            </div>
          </div>
        </Feature>

        <v-checkbox
          v-model="acceptOnboarding"
          label="Ich bestätige die Richtigkeit aller Eingaben und Daten.
          Mit dem Klick auf 'Onboarding abschließen und System einrichten' wird Ihr System eingerichtet
          und zur Prüfung an das Customer-Success-Team übermittelt"
          class="onboarding-agreement"
        />
        <div class="d-flex flex-column align-center">
          <v-btn
            class="ma-4"
            :class="{'mx-0 btn-multiline': $vuetify.breakpoint.smAndDown, 'mx-16': $vuetify.breakpoint.mdAndUp}"
            :height="$vuetify.breakpoint.smAndDown ? 80 : 35"
            color="primary"
            :disabled="!accept"
            @click="submit"
          >
            <span class="text-wrap">
              Onboarding abschließen und System einrichten
            </span>
          </v-btn>
          <v-alert
            v-if="error && !isComplete"
            type="error"
            tile
            text
          >
            {{ error }}
            <ul>
              <li
                v-for="([step, missing]) in filterdMissingData"
                :key="step"
              >
                {{ step + ': ' + missing.map((field) => translations[field]).join(', ') }}
              </li>
            </ul>
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SUBMIT_ONBOARDING from './queries/SubmitOnboarding.gql'
import bus, { eventNames } from '@/lib/eventBus'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'

const FIELDS = {
  about: 'Link zu Ihrer Teamseite',
  adAccounts: 'Facebook Business Manager Ad Accounts',
  bookedLandingpageSlugs: 'Gebuchte Ratgeber',
  city: 'Stadt',
  country: 'Land',
  email: 'E-Mail',
  facebookBudget: 'Facebook Budget',
  facebookDistributeBudget: 'Verteilung des Facebook Budget',
  facebookZip: 'Facebook PLZs',
  firstname: 'Vorname',
  googleBudget: 'Google Budget',
  googleDistributeBudget: 'Verteilung des Google Budget',
  googleZip: 'Google PLZs',
  iban: 'IBAN',
  bic: 'BIC',
  imageUrl: 'Portraitbild',
  imprint: 'Link zu Ihrem Impressum',
  lastname: 'Nachname',
  leadgenTos: 'Facebook Business Manager Lead Ads',
  link: 'Link zu Ihrer Facebookseite',
  logoUrl: 'Logo',
  mobile: 'Mobiltelefon',
  name: 'Name',
  onboardingSepa: 'SEPA-Lastschriftmandat',
  pages: 'Facebook Business Manager Seite',
  phone: 'Telefon',
  privacy: 'Link zu Ihrer Datenschutzseite',
  role: 'Rolle',
  salutation: 'Anrede',
  sepaValidAsOf: 'SEPA gültig ab',
  slogan: 'Slogan',
  street: 'Straße',
  text: 'Signatur',
  vatId: 'Umsatzsteuer ID',
  website: 'Link zu Ihrer Webseite',
  emailValidation: 'Validierung der Absender-E-Mail',
  zip: 'PLZ'
}

export default {
  mixins: [brandingMixin, featureMixin],
  props: {
    isComplete: {
      type: Boolean,
      required: true
    },
    companyId: {
      type: String,
      required: true
    },
    missingData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      acceptAgb: false,
      acceptPrivacy: false,
      acceptGuideline: false,
      acceptOnboarding: false,
      error: '',
      translations: FIELDS
    }
  },
  computed: {
    filterdMissingData () {
      return Object.entries(this.missingData).filter(([key, value]) => value.length > 0)
    },
    accept () {
      const acceptAgb = !this.isFeatureActive(this.featureNames.DATA_PRIVACY) ||
          !this.getFeature(this.featureNames.DATA_PRIVACY).hasConditions ||
          this.acceptAgb
      const acceptGuideline = !this.isFeatureActive(this.featureNames.MATERIAL_GUIDE) ||
          this.acceptGuideline
      return [acceptAgb, this.acceptPrivacy, acceptGuideline, this.acceptOnboarding].every(Boolean)
    }
  },
  methods: {
    async submit () {
      if (!this.isComplete) {
        this.error = 'Bitte vervollständigen Sie zuerst alle Schritte des Onboardings. Folgende Daten werden noch benötigt:'
        return
      }
      await this.$apollo.mutate({
        mutation: SUBMIT_ONBOARDING,
        variables: { companyId: this.companyId }
      })
      this.error = ''
      bus.$emit(eventNames.TRIGGER_ANIMATION)

      this.$router.go('/onboarding-validation')
    }
  }
}
</script>

<style>
.onboarding-agreement > * .v-input__slot {
  align-items:start !important;
}
</style>
