<template>
  <step-container
    :required-data="requiredData"
    :data="{ validated }"
    :on-submit="()=>({})"
    has-steps
    v-on="$listeners"
  >
    <v-alert
      v-if="!email"
      text
      class="mx-4"
      type="warning"
    >
      Um Ihre E-Mail-Adresse zu Validieren tragen Sie diese zuerst unter <a href="/onboarding/publication">Publikation</a> ein.
    </v-alert>
    <v-alert
      v-if="!companyName"
      text
      class="mx-4"
      type="warning"
    >
      Um Ihre E-Mail-Adresse zu Validieren tragen Sie zuerst Ihren Firmennamen unter <a href="/onboarding/company">Firmendaten</a> ein.
    </v-alert>
    <v-alert
      v-if="validated"
      text
      class="mx-4"
      type="success"
    >
      Ihre E-Mail-Adresse <strong> {{ defaultSender.name }} &lt;{{ defaultSender.fromEmail }}> </strong> wurde erfolgreich validiert.<br>
      Die E-Mail-Adresse kann nach Abschluss des Onboardings in den Einstellungen geändert werden.
    </v-alert>
    <EmailVerification
      v-if="email && companyName"
      :default-sender="defaultSender"
    />
  </step-container>
</template>

<script>
import HOPPERMATION_DEFAULT_SENDER from '@/modules/emailVerification/queries/HoppermationDefaultSender.gql'
import HOPPERMATION_ORGANIZATION from '@/modules/emailVerification/queries/HoppermationOrganization.gql'
import StepContainer from '@/modules/company/StepContainer.vue'
import EmailVerification from '@/modules/emailVerification'

export default {
  components: { StepContainer, EmailVerification },
  props: {
    email: {
      type: String,
      default: ''
    },
    companyName: {
      type: String,
      default: ''
    }
  },
  computed: {
    validated () {
      return this.organization?.status === 'ACTIVE'
    }
  },
  apollo: {
    defaultSender: {
      query: HOPPERMATION_DEFAULT_SENDER,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    organization: {
      query: HOPPERMATION_ORGANIZATION,
      pollInterval: 60000,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  },
  methods: {
    requiredData () {
      return { emailValidation: this.validated }
    }
  }
}
</script>
